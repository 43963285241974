import { capitalize } from 'lodash';
import { hasRessourceAccess } from '../navigation-guards.utils';

// Meters
const Meters = () => import(/* webpackChunkName: "meters" */ '@/pages/Meters.vue');
const MetersCreationForm = () =>
    import(/* webpackChunkName: "meters" */ '@/components/meters/MetersCreationForm/MetersCreationForm.vue');
const MetersPanel = () => import(/* webpackChunkName: "meters" */ '@/components/meters/MetersPanel/MetersPanel.vue');
const MetersPanelInfos = () =>
    import(/* webpackChunkName: "meters" */ '@/components/meters/MetersPanel/MetersPanelInfos.vue');
const MeterData = () => import(/* webpackChunkName: "meters" */ '@/components/meters/MetersPanel/Meter/MeterData.vue');
const MeterRawData = () =>
    import(/* webpackChunkName: "meters" */ '@/components/meters/MetersPanel/Meter/MeterRawData.vue');
const MeterComments = () =>
    import(/* webpackChunkName: "meters" */ '@/components/meters/MetersPanel/Meter/MeterComments.vue');
const MeterDocuments = () =>
    import(/* webpackChunkName: "meters" */ '@/components/meters/MetersPanel/Meter/MeterDocuments.vue');

export default {
    name: 'customer.meters',
    path: 'meters',
    component: Meters,
    meta: {
        title: 'METERS_TITLE',
    },
    children: [
        {
            name: 'meters-new',
            path: 'new',
            components: {
                fullPageLayer: MetersCreationForm,
            },
            props: {
                fullPageLayer: (route) => ({ type: route.query.type }),
            },
            async beforeEnter(to, from, next) {
                const type = to.query.type;
                const subjectType = type === 'virtual' ? 'meter' : type;
                const hasAccess = await hasRessourceAccess('create', capitalize(subjectType));
                if (hasAccess) {
                    next();
                } else {
                    next({
                        name: 'customer.meters',
                        params: { customerCode: to.params.customerCode },
                    });
                }
            },
        },
        {
            name: 'meter',
            path: ':id',
            components: {
                layer: MetersPanel,
            },
            children: [
                {
                    name: 'meter-infos',
                    path: 'infos',
                    component: MetersPanelInfos,
                },
                {
                    name: 'meter-data',
                    path: 'data',
                    component: MeterData,
                },
                {
                    name: 'meter-raw-data',
                    path: 'raw-data',
                    component: MeterRawData,
                },
                {
                    name: 'meter-comments',
                    path: 'comments',
                    component: MeterComments,
                },
                {
                    name: 'meter-documents',
                    path: 'documents',
                    component: MeterDocuments,
                },
            ],
        },
    ],
};
