import { t } from 'i18next';
import _ from 'lodash';
import { arrayToTree } from 'performant-array-to-tree';
import { createAction, deleteAction, getAction, getActions, updateAction } from '@/api/actions.service';
import { createFolder, deleteFolder, getFolders, updateFolder } from '@/api/folders.service';
import { notify } from '@/helpers/notifications';
import { NAMESPACE as NS_USERS } from '@/store/modules/users';
import dataModule, { actionTypes as dataActionTypes } from '@/store/reusable-modules/data-list.module';
import { addEdition } from '@/store/utils/edition.utils';
import { addFullscreen } from '@/store/utils/fullscreen.utils';
import {
    DUPLICATE_ACTION,
    GO_TO_ACTION,
    GO_TO_ACTIONS,
    GO_TO_CREATE_ITEM,
    MOVE_ACTION_OR_FOLDER_TO_PARENT,
    UPDATE_ACTION,
    UPDATE_ACTION_BY_ID,
    UPDATE_FOLDER,
} from './action-types';
import { SET_LAST_EXPANDED_ELEMENT_ID, UPDATE_ACTION_SUCCESS, UPDATE_FOLDER_SUCCESS } from './mutation-types';

/** @typedef { import('@/api/actions.service.js').Action }  */

/**
 * @typedef {Object} ActionsState      Last selected view can be tree or table
 * @property {boolean}              lastExpandedElementId
 */

/** @type {ActionsState} */
const _state = {
    lastExpandedElementId: null,
};

export const mutations = {
    /**
     * @param {ActionsState} state
     * @param {Action} action
     */
    [UPDATE_ACTION_SUCCESS](state, action) {
        const index = state.actions.items.findIndex(({ id }) => id === action.id);
        if (index >= 0) {
            state.actions.items.splice(index, 1, { ...action });
        }
    },
    /**
     * @param {DocumentState} state
     * @param {*} updatedFolder
     */
    [UPDATE_FOLDER_SUCCESS](state, updatedFolder) {
        const folders = state?.folders?.items ?? [];
        if (folders) {
            const index = folders.findIndex(({ id }) => id === updatedFolder.id);
            folders.splice(index, 1, updatedFolder);
        }
    },
    /**
     * Save last expanded element id.
     *
     * @param {ActionsState} state
     * @param {string}      expandedElementId
     */
    [SET_LAST_EXPANDED_ELEMENT_ID](state, expandedElementId) {
        state.lastExpandedElementId = expandedElementId;
    },
};

export const getters = {
    /**
     * Indicates whether the action are being creating
     * @param {ActionState}  state
     * @returns {boolean}   `true` if action are being creating
     */
    isCreatingAction: (state) => {
        return state.actions.pending.create;
    },
    /**
     * Indicates whether the folder are being creating
     * @param {ActionState}  state
     * @returns {boolean}   `true` if folder are being creating
     */
    isCreatingFolder: (state) => {
        return state.folders.pending.create;
    },
    /**
     * Return the ID corresponding to the route `:id`.
     *
     * @return {string} ID of the action
     */
    selectedItemId: (state, _getters, rootState) => {
        return rootState.route?.params?.id;
    },
    /**
     * Return the action corresponding to the route `:id`. Otherwise `undefined`.
     *
     * @return {Action}
     */
    selectedItem: (state, _getters) => _getters.actionsAndFoldersById[_getters.selectedItemId],
    /**
     * Return the meters of the selectedAction
     *
     * @param {ActionState} state
     * @param {ActionsGetters} _getters
     * @returns {array}
     */
    meters: (state, _getters) => {
        return Object.values(_getters.selectedItem?.meters ?? []);
    },
    /**
     * Return the action corresponding to an id. Otherwise `undefined`.
     *
     * @return {Action}
     */
    itemsById: (state, _getters) => _getters.actionsAndFoldersById,
    folders: (state) => {
        return _.get(state, 'folders.items') || [];
    },
    actionsAndFolders: (state, _getters) => {
        return [
            ...(state.actions.items || []).map((action) => ({
                type: 'action',
                ...action,
            })),
            ..._getters.folders.map((folder) => ({ type: 'folder', icon: { name: 'folder' }, ...folder })),
        ];
    },
    actionsAndFoldersById: (state, _getters) => {
        return _.keyBy(_getters.actionsAndFolders, 'id');
    },
    /**
     * Return the actions tree.
     * @param {ActionsState} state
     * @param {ActionsGetters} _getters
     * @returns {object}
     */
    actionsTree: (state, _getters) => {
        const tree = arrayToTree(_getters.actionsAndFolders, { dataField: null });
        return {
            id: 'root',
            children: tree.map((rootItem) => ({
                ...rootItem,
                parentId: 'root',
            })),
        };
    },
    /**
     * Return the folder by id
     * @param {DashboardState} state
     * @param {DashboardGetters} _getters
     * @returns {object}
     */
    getFolderById: (state, _getters) => (folderId) => {
        return _getters.folders.find(({ id }) => id === folderId);
    },
};

export const actions = {
    /**
     * Navigate to actions list.
     *
     * @param {Object} context
     * @param {Object} payload
     */
    [GO_TO_ACTIONS]({ rootGetters }, { router }) {
        router.push({
            path: `/${rootGetters.customerCode}/actions`,
        });
    },

    /**
     * Navigate to an action.
     *
     * @param {Object} context
     * @param {Object} payload
     * @param {string} payload.actionId
     */
    [GO_TO_ACTION](context, { id, router }) {
        const query = {
            ...router.currentRoute.query,
        };

        if (
            router.currentRoute.matched.every(({ name }) => name !== 'actions') ||
            router.currentRoute.name === 'actions' ||
            router.currentRoute.name === 'actions-new'
        ) {
            // case when click on action in actions list or create new element
            router.push({
                name: 'action-infos',
                params: {
                    id,
                },
                query,
            });
        } else {
            router.push({
                name: router.currentRoute.name,
                params: {
                    id,
                },
                query,
            });
        }
    },

    /**
     * Navigate to creation form
     */
    [GO_TO_CREATE_ITEM](context, { router, type }) {
        const query = { type };

        router.push({
            name: 'actions-new',
            query,
        });
    },

    async [DUPLICATE_ACTION]({ rootGetters, dispatch }, { action, router }) {
        await dispatch(`actions/${dataActionTypes.CREATE_ITEM}`, {
            item: {
                title: `${action.title} ${t('ACTIONS_CLONED_TITLE_SUFFIX')}`,
                ownerId: rootGetters[`${NS_USERS}/currentUserId`],
                parentId: action.parentId,
            },
            router,
            copyFrom: action.id,
        });
    },
    async [UPDATE_ACTION]({ commit, rootGetters }, item) {
        try {
            const editedAction = await updateAction({
                customerCode: rootGetters.customerCode,
                action: item,
            });
            notify({
                type: 'success',
                text: t('UPDATE_ACTION_SUCCESS'),
            });
            commit(UPDATE_ACTION_SUCCESS, editedAction);
        } catch (error) {
            notify({
                type: 'error',
                text: t('UPDATE_ACTION_ERROR'),
            });
            throw error;
        }
    },
    async [MOVE_ACTION_OR_FOLDER_TO_PARENT]({ commit, dispatch, rootGetters, getters: _getters }, { moved, to }) {
        const movedItem = _getters.actionsAndFoldersById[moved];
        const toItem = to === 'root' ? { id: null } : _getters.actionsAndFoldersById[to];

        if (movedItem && toItem) {
            try {
                const updatedItem = {
                    ...movedItem,
                    parentId: toItem.id,
                };

                if (movedItem.type === 'folder') {
                    await updateFolder({
                        customerCode: rootGetters.customerCode,
                        folder: updatedItem,
                    });
                    commit(UPDATE_FOLDER_SUCCESS, updatedItem);
                } else {
                    await updateAction({
                        customerCode: rootGetters.customerCode,
                        action: updatedItem,
                    });
                    commit(UPDATE_ACTION_SUCCESS, updatedItem);
                }
            } catch (error) {
                notify({
                    type: 'error',
                    text: t('ITEM_MOVE_ERROR'),
                });

                // Refresh actions to restore the correct state
                dispatch(dataActionTypes.FETCH_ITEMS);
                dispatch(`folders/${dataActionTypes.FETCH_ITEMS}`);
            }
        }
    },
    async [UPDATE_ACTION_BY_ID]({ commit, rootGetters }, id) {
        try {
            const item = await getAction({
                customerCode: rootGetters.customerCode,
                id,
            });
            commit(UPDATE_ACTION_SUCCESS, item);
        } catch (error) {
            notify({
                type: 'error',
                text: t('UPDATE_ACTION_ERROR'),
            });
            throw error;
        }
    },
    async [UPDATE_FOLDER]({ commit, rootGetters }, folder) {
        if (!folder.description) {
            folder.description = '';
        }
        try {
            const updatedFolder = await updateFolder({
                customerCode: rootGetters.customerCode,
                folder,
            });
            commit(UPDATE_FOLDER_SUCCESS, updatedFolder);
        } catch (error) {
            notify({
                type: 'error',
                text: t('UPDATE_FOLDER_ERROR'),
            });
            throw new Error(error.response.data);
        }
    },
};

export const NAMESPACE = 'actions';

export default addFullscreen(
    addEdition(
        {
            namespaced: true,
            state: _state,
            actions,
            mutations,
            getters,
            getPersistedStatePaths() {
                return [`${NAMESPACE}.lastExpandedElementId`];
            },
            modules: {
                actions: dataModule({
                    async getItems({ rootGetters }, { sort, filters }) {
                        return getActions({
                            customerCode: rootGetters.customerCode,
                            sort,
                            filters,
                        });
                    },
                    async getItem({ rootGetters }, id) {
                        return getAction({ customerCode: rootGetters.customerCode, id });
                    },
                    async createItem({ dispatch, rootGetters }, { copyFrom, item, router }) {
                        try {
                            const createdAction = await createAction({
                                customerCode: rootGetters.customerCode,
                                action: item,
                                copyFrom,
                            });
                            notify({ type: 'success', text: t('CREATE_ACTION_SUCCESS') });

                            dispatch(`${NAMESPACE}/${GO_TO_ACTION}`, { id: createdAction.id, router }, { root: true });
                            return createdAction;
                        } catch (error) {
                            notify({ type: 'error', text: t('CREATE_ACTION_ERROR') });
                            throw error;
                        }
                    },
                    async deleteItem({ dispatch, rootGetters }, { item, router, view }) {
                        try {
                            await deleteAction({
                                customerCode: rootGetters.customerCode,
                                id: item.id,
                            });
                            notify({
                                type: 'success',
                                text: t('DELETE_ACTION_SUCCESS'),
                            });

                            if (view === 'table' || !item.parentId) {
                                dispatch(`${NAMESPACE}/${GO_TO_ACTIONS}`, { router }, { root: true });
                            } else {
                                dispatch(`${NAMESPACE}/${GO_TO_ACTION}`, { id: item.parentId, router }, { root: true });
                            }
                        } catch (error) {
                            notify({
                                type: 'error',
                                text: t('DELETE_ACTION_ERROR'),
                            });
                            throw error;
                        }
                    },
                }),
                folders: addEdition(
                    dataModule({
                        namespaced: true,
                        async getItems({ rootGetters }, { fields }) {
                            return await getFolders({
                                customerCode: rootGetters.customerCode,
                                fields,
                                filters: { context: 'actions' },
                            });
                        },
                        async createItem({ dispatch, rootGetters }, { item, router }) {
                            try {
                                const createdFolder = await createFolder({
                                    customerCode: rootGetters.customerCode,
                                    folder: {
                                        ...item,
                                        context: 'actions',
                                    },
                                });
                                notify({ type: 'success', text: t('CREATE_FOLDER_SUCCESS') });

                                dispatch(
                                    `${NAMESPACE}/${GO_TO_ACTIONS}`,
                                    {
                                        query: { folder: createdFolder.id },
                                        router,
                                    },
                                    { root: true },
                                );
                                return createdFolder;
                            } catch (error) {
                                notify({ type: 'error', text: t('CREATE_FOLDER_ERROR') });
                                throw error;
                            }
                        },
                        async deleteItem({ dispatch, rootGetters }, { item, router }) {
                            try {
                                await deleteFolder({
                                    customerCode: rootGetters.customerCode,
                                    id: item.id,
                                });

                                notify({
                                    type: 'success',
                                    text: t('DELETE_FOLDER_SUCCESS'),
                                });

                                // Refresh actions since some actions have been deleted
                                dispatch(`${NAMESPACE}/actions/${dataActionTypes.FETCH_ITEMS}`, undefined, {
                                    root: true,
                                });
                                if (item.parentId) {
                                    dispatch(
                                        `${NAMESPACE}/${GO_TO_ACTION}`,
                                        { id: item.parentId, router },
                                        { root: true },
                                    );
                                } else {
                                    dispatch(`${NAMESPACE}/${GO_TO_ACTIONS}`, { router }, { root: true });
                                }
                            } catch (error) {
                                notify({
                                    type: 'success',
                                    text: t('DELETE_FOLDER_ERROR'),
                                });
                                throw error;
                            }
                        },
                    }),
                    {
                        async saveFunction({ dispatch }, folder) {
                            dispatch(`${NAMESPACE}/${UPDATE_FOLDER}`, folder, { root: true });
                        },
                    },
                ),
            },
        },
        {
            saveFunction: actions[UPDATE_ACTION],
        },
    ),
);
